import * as XLSX from "xlsx";
import { useDispatch, useSelector } from 'react-redux';
import { paySpecListRequest, paySpecUploadRequest } from "../../reducers/paySpec";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import TokenService from "../../service/TokenService";
import { useNavigate } from "react-router";
import FcmService from "../../service/FcmService";
import NavBarComp from "../NavBarComp";
import Form from 'react-bootstrap/Form';

const ManagePaySpec = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    var date = new Date();
    var this_month = date.getMonth() + 1;
    var this_year = date.getFullYear();
    if(this_month < 10){
        this_month = "0"+this_month;
    }
    const [month, setMonth] = useState(String(this_month));
    const [year, setYear] = useState(String(this_year));
    const [point, setPoint] = useState("김포센터");

    const error = useSelector(
        (state) => state.paySpec.paySpecError,
    );

    const success = useSelector(
        (state) => state.paySpec.paySpecDone,
    );

    const data = useSelector(
        (state) => state.paySpec.paySpecData,
    );

    const actError = useSelector(
        (state) => state.paySpec.paySpecActError,
    );

    const actSuccess = useSelector(
        (state) => state.paySpec.paySpecActDone,
    );

    const actData = useSelector(
        (state) => state.paySpec.paySpecActData,
    );

    useEffect(() => {
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(paySpecListRequest({"point":point, "year":this_year, "month":this_month}));
        });
    },[]);

    useEffect(()=>{
        if(actError){
            alert(actError.msg);
            window.location.reload();
        }
            
        if(actData !== null && actData !== undefined){
            alert("저장이 완료 되었습니다.");
            TokenService.tokenIss().then(res => {
                if(res === ""){
                    alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                    navigate("/signin");
                }
                
                setYear(actData.year);
                setMonth(actData.month);
                setPoint(actData.point);
                dispatch(paySpecListRequest({"year":actData.year, "month":actData.month, "point":actData.point}));
            });
        }
    }, [actData]);

    const onFileSelected = (file) =>{
        let input = file.target;
        let reader = new FileReader();

        reader.onload = function () {
            let data = reader.result;
            let workBook = XLSX.read(data, { type: 'binary' });
            
            let rows = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]], {header: 1,defval:'', raw:false});//첫번째 시트만 가져오기
            let year = workBook.SheetNames[0].substring(0,4);
            let month = workBook.SheetNames[0].substring(6,8);
            let title = Object.values(rows[0]); 
            
            rows.splice(0,1);
            TokenService.tokenIss().then(res => {
                if(res === ""){
                    alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                    navigate("/signin");
                }
                dispatch(paySpecUploadRequest({paySpecData: rows, month : month, year : year, title:title}));
            });
        };
        reader.readAsBinaryString(input.files[0]);
    }

    function commaValue(val){
        if(val != undefined){
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
    return (
        <>
            <NavBarComp />
            <div style={{"paddingTop":"70px"}}>
                <div style={{"display": "flex","paddingBottom":"10px"}}>
                    <div style={{"flex":"1"}}>
                        <input 
                            type="file" 
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={onFileSelected}>
                        </input>
                    </div>
                    <div style={{"flex": "1", "textAlign": "left", "paddingLeft":"20px"}}>
                    </div>
                </div>

                <div style={{"display": "flex", "paddingBottom":"10px"}}>
                    <div style={{"flex":"1"}}>
                        <Form.Select onChange={(e)=>{
                                setPoint(e.target.value);
                                TokenService.tokenIss().then(res => {
                                    if(res === ""){
                                        alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                                        navigate("/signin");
                                    }
                                    dispatch(paySpecListRequest({"point":e.target.value, "year":year, "month":month}));
                                });
                            }} value={point}>
                                <option value="김포센터">김포센터</option>
                                <option value="월계점">월계점</option>
                                <option value="자양점하월곡점">자양점, 하월곡점</option>
                        </Form.Select>
                    </div>
                    <div style={{"flex":"1"}}>
                        <Form.Select onChange={(e)=>{
                                setYear(e.target.value);
                                TokenService.tokenIss().then(res => {
                                    if(res === ""){
                                        alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                                        navigate("/signin");
                                    }
                                    dispatch(paySpecListRequest({"year":e.target.value, "month":month, "point":point}));
                                });
                            }} value={year}>
                                <option value="2022">2022년</option>
                                <option value="2023">2023년</option>
                                <option value="2024">2024년</option>
                                <option value="2025">2025년</option>
                                <option value="2026">2026년</option>
                                <option value="2027">2027년</option>
                        </Form.Select>
                    </div>

                    <div style={{"flex":"1"}}>
                        <Form.Select onChange={(e)=>{
                                setMonth(e.target.value);
                                TokenService.tokenIss().then(res => {
                                    if(res === ""){
                                        alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                                        navigate("/signin");
                                    }
                                    dispatch(paySpecListRequest({"point":point,"month":e.target.value, "year":year}));
                                });
                            }} value={month}>
                                <option value="01">01월</option>
                                <option value="02">02월</option>
                                <option value="03">03월</option>
                                <option value="04">04월</option>
                                <option value="05">05월</option>
                                <option value="06">06월</option>
                                <option value="07">07월</option>
                                <option value="08">08월</option>
                                <option value="09">09월</option>
                                <option value="10">10월</option>
                                <option value="11">11월</option>
                                <option value="12">12월</option>
                        </Form.Select>
                    </div>
                    
                    <div style={{"flex": "1", "textAlign": "left", "paddingLeft":"20px"}}>
                        <Button variant="secondary" onClick={()=> {
                            if(data.length > 0){
                                if (window.confirm(`${point} ${month} 월 데이터를 전송하시겠습니까?`)) {
                                    TokenService.tokenIss().then(res => {
                                        if(res === ""){
                                            alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                                            navigate("/signin");
                                        }
                                    });
                                    FcmService.sendFcmMsg({"val":year+""+month, "title":+year+"년 "+month+" 월 급여내역", "content":year+"년 "+month+" 월 급여내역서가 발송되었습니다.", "type":"paySpec", "point":point}).then(res => {
                                        if(res === "ok"){
                                            alert("메세지 전송을 완료 하였습니다.");
                                        }
                                    });
                                }
                            } else {
                                alert('전송할 데이터가 없습니다.');
                            }
                        }}>메세지전송</Button>
                    </div>
                </div>

                <div>
                    <Table style={{width:"4200px"}}> 
                        <thead> 
                            {
                                point !== null && point !== undefined?
                                    point === "김포센터"?
                                    <tr>
                                        <th>번호</th>
                                        <th>점포명</th>
                                        <th>파트너사</th> 
                                        <th>배송기사명</th> 
                                        <th>배송기사ID</th> 
                                        <th>차량번호</th> 
                                        <th>T/O</th> 
                                        <th>운행일수</th> 
                                        <th>운행횟수</th> 
                                        <th>배송처수</th> 
                                        <th>총운행거리</th> 
                                        <th>기본도급료</th> 
                                        <th>제수당</th> 
                                        <th>근속수당</th> 
                                        <th>운행거리INC[유류비]</th> 
                                        <th>배송거리INC[배송구간]</th> 
                                        <th>배송건INC[추가배송건]</th> 
                                        <th>박스INC[추가박스]</th> 
                                        <th>굿모닝INC[조출,건수지원]</th> 
                                        <th>공제[기사변제]</th> 
                                        <th>특수권역운영INC[톨게이트지원금]</th> 
                                        <th>온도관제 위반[3점 이상 0.5% 삭감]</th> 
                                        <th>총합계</th> 
                                        <th>gps , 용차비공제</th> 
                                        <th>백신지원/격리지원금</th> 
                                        <th>기사 지급액</th> 
                                        <th>관리비및부가세</th> 
                                        <th>회차및갱신보험</th> 
                                        <th>환경부담금</th> 
                                        <th>협회비</th> 
                                        <th>자동차세</th> 
                                        <th>과태료공제</th> 
                                        <th>전월미수및기타비용</th> 
                                        <th>기타분납금</th>
                                        <th>합계</th>
                                        <th>대한통운 지급금액</th>
                                        <th>기사 급여이체</th>
                                    </tr>
                                    :point === "월계점"?
                                    <tr>
                                        <th>번호</th>
                                        <th>운영타입</th>
                                        <th>점포명</th>
                                        <th>배송기사명</th> 
                                        <th>배송기사ID</th> 
                                        <th>도급일</th> 
                                        <th>총 배송 처수</th> 
                                        <th>총 운행 거리</th> 
                                        <th>평균운행 거리</th> 
                                        <th>반올림</th> 
                                        <th>건수 인센티브</th> 
                                        <th>특수권역 인센티브</th> 
                                        <th>인센합계</th> 
                                        <th>수기배송</th> 
                                        <th>용차비</th> 
                                        <th>공제비용</th> 
                                        <th>기본도급료(공제대상)</th> 
                                        <th>합계 기본료+인센</th> 
                                        <th>백신 휴무 지원</th> 
                                        <th>경조사 지원금]</th> 
                                        <th>기사 지금액 지입료 공제 후</th> 
                                        <th>관리비 및 부가세</th> 
                                        <th>회차 및 갱신보험</th> 
                                        <th>환경부담금</th> 
                                        <th>협회비</th> 
                                        <th>자동차세</th> 
                                        <th>과태료공제</th> 
                                        <th>전월미수 및 기타비용</th>
                                        <th>기타분납금</th> 
                                        <th>합계</th> 
                                        <th>대한통운 지급액</th> 
                                        <th>마진</th> 
                                        <th>기사 급여이체</th> 
                                    </tr>
                                    :point === "자양점"?
                                    <tr>
                                        <th>번호</th>
                                        <th>운영타입</th>
                                        <th>점포명</th>
                                        <th>배송기사명</th> 
                                        <th>배송기사ID</th> 
                                        <th>도급일</th> 
                                        <th>총 배송 처수</th> 
                                        <th>총 운행 거리</th> 
                                        <th>평균운행 거리</th> 
                                        <th>반올림</th> 
                                        <th>건수 인센티브</th> 
                                        <th>TR 인센티브</th>
                                        <th>장거리 인센티브(PP/TR)</th>
                                        <th>기본거리 인센티브(TR)</th>
                                        <th>야간 인센티브</th>
                                        <th>특수권역 인센티브</th> 
                                        <th>인센합계</th> 
                                        <th>수기배송</th> 
                                        <th>온도관제미설치</th> 
                                        <th>공제비용</th> 
                                        <th>기본도급료(공제대상)</th> 
                                        <th>유니폼 144</th>
                                        <th>차량청결 383</th>
                                        <th>기타수당 1660</th>
                                        <th>인센티브 2200</th>
                                        <th>인센티브 합계</th>
                                        <th>기본료 합계</th>
                                        <th>합계 기본료+인센</th> 
                                        <th>백신 휴무 지원</th> 
                                        <th>경조사 지원금]</th> 
                                        <th>기사 지금액 지입료 공제 후</th> 
                                        <th>관리비 및 부가세</th> 
                                        <th>회차 및 갱신보험</th> 
                                        <th>환경부담금</th> 
                                        <th>협회비</th> 
                                        <th>산재/고용보험</th>
                                        <th>과태료공제</th> 
                                        <th>전월미수 및 기타비용</th> 
                                        <th>기타분납금</th>
                                        <th>합계</th> 
                                        <th>대한통운 지급액</th> 
                                        <th>마진</th> 
                                        <th>기사 급여이체</th> 
                                    </tr>
                                    :point === "하월곡점"?
                                    <tr>
                                        <th>번호</th>
                                        <th>운영타입</th>
                                        <th>점포명</th>
                                        <th>배송기사명</th> 
                                        <th>배송기사ID</th> 
                                        <th>도급일</th> 
                                        <th>총 배송 처수</th> 
                                        <th>총 운행 거리</th> 
                                        <th>평균운행 거리</th> 
                                        <th>반올림</th> 
                                        <th>건수 인센티브</th> 
                                        <th>TR 인센티브</th>
                                        <th>장거리 인센티브(PP/TR)</th>
                                        <th>기본거리 인센티브(TR)</th>
                                        <th>야간 인센티브</th>
                                        <th>특수권역 인센티브</th> 
                                        <th>인센합계</th> 
                                        <th>수기배송</th> 
                                        <th>온도관제미설치</th> 
                                        <th>공제비용</th> 
                                        <th>기본도급료(공제대상)</th> 
                                        <th>유니폼 144</th>
                                        <th>차량청결 383</th>
                                        <th>기타수당 1660</th>
                                        <th>인센티브 2200</th>
                                        <th>기본료 합계</th>
                                        <th>합계 기본료+인센</th> 
                                        <th>백신 휴무 지원</th> 
                                        <th>경조사 지원금]</th> 
                                        <th>기사 지금액 지입료 공제 후</th> 
                                        <th>관리비 및 부가세</th> 
                                        <th>회차 및 갱신보험</th> 
                                        <th>환경부담금</th> 
                                        <th>협회비</th> 
                                        <th>산재/고용보험</th>
                                        <th>과태료공제</th> 
                                        <th>전월미수 및 기타비용</th> 
                                        <th>기타분납금</th>
                                        <th>합계</th> 
                                        <th>대한통운 지급액</th> 
                                        <th>마진</th> 
                                        <th>기사 급여이체</th> 
                                    </tr>
                                    :null
                                :null
                            }
                                
                        </thead> 
                        <tbody> 
                        {
                            Array.isArray(data)? data.map((item, i) => { 
                                return ( 
                                    item.점포명 === "김포센터"?
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{item.점포명}</td>
                                        <td>{item.파트너사}</td>
                                        <td>{item.배송기사명}</td>
                                        <td>{item.배송기사ID}</td>
                                        <td>{item.차량번호}</td>
                                        <td>{item.TO}</td>
                                        <td>{item.운행일수}</td>
                                        <td>{item.운행횟수}</td>
                                        <td>{commaValue(item.배송처수)}</td>
                                        <td>{commaValue(item.총운행거리)}</td>
                                        <td>{commaValue(item.기본도급료)}</td>
                                        <td>{commaValue(item.제수당)}</td>
                                        <td>{commaValue(item.근속수당)}</td>
                                        <td>{commaValue(item.운행거리INC유류비)}</td>
                                        <td>{commaValue(item.배송거리INC배송구간)}</td>
                                        <td>{commaValue(item.배송건INC추가배송건)}</td>
                                        <td>{commaValue(item.박스INC추가박스)}</td>
                                        <td>{commaValue(item.굿모닝INC조출건수지원)}</td>
                                        <td>{commaValue(item.공제기사변제)}</td>
                                        <td>{commaValue(item.특수권역운영INC톨게이트지원금)}</td>
                                        <td>{commaValue(item.온도관제위반3점이상05삭감)}</td>
                                        <td>{commaValue(item.총합계)}</td>
                                        <td>{commaValue(item.gps용차비공제)}</td>
                                        <td>{commaValue(item.백신지원격리지원금)}</td>
                                        <td>{commaValue(item.기사지급액)}</td>
                                        <td>{commaValue(item.관리비및부가세)}</td>
                                        <td>{commaValue(item.회차및갱신보험)}</td>
                                        <td>{commaValue(item.환경부담금)}</td>
                                        <td>{commaValue(item.협회비)}</td>
                                        <td>{commaValue(item.자동차세)}</td>
                                        <td>{commaValue(item.과태료공제)}</td>
                                        <td>{commaValue(item.전월미수및기타비용)}</td>
                                        <td>{commaValue(item.기타분납금)}</td>
                                        <td>{commaValue(item.합계)}</td>
                                        <td>{commaValue(item.대한통운지급금액)}</td>
                                        <td>{commaValue(item.기사급여이체)}</td>
                                    </tr>
                                    :item.점포명 === "월계점"?
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{item.운영타입}</td>
                                        <td>{item.점포명}</td>
                                        <td>{item.배송기사명}</td>
                                        <td>{item.배송기사ID}</td>
                                        <td>{item.도급일}</td>
                                        <td>{commaValue(item.총배송처수)}</td>
                                        <td>{commaValue(item.총운행거리)}</td>
                                        <td>{commaValue(item.평균운행거리)}</td>
                                        <td>{commaValue(item.반올림)}</td>
                                        <td>{commaValue(item.건수인센티브)}</td>
                                        <td>{commaValue(item.특수권역인센티브)}</td>
                                        <td>{commaValue(item.인센합계)}</td>
                                        <td>{commaValue(item.수기배송)}</td>
                                        <td>{commaValue(item.용차비)}</td>
                                        <td>{commaValue(item.공제비용)}</td>
                                        <td>{commaValue(item.기본도급료공제대상)}</td>
                                        <td>{commaValue(item.합계기본료인센)}</td>
                                        <td>{commaValue(item.백신휴무지원금)}</td>
                                        <td>{commaValue(item.경조사지원금)}</td>
                                        <td>{commaValue(item.기사지급액지입료공제후)}</td>
                                        <td>{commaValue(item.관리비및부가세)}</td>
                                        <td>{commaValue(item.회차및갱신보험)}</td>
                                        <td>{commaValue(item.환경부담금)}</td>
                                        <td>{commaValue(item.협회비)}</td>
                                        <td>{commaValue(item.자동차세)}</td>
                                        <td>{commaValue(item.과태료공제)}</td>
                                        <td>{commaValue(item.전월미수및기타비용)}</td>
                                        <td>{commaValue(item.기타분납금)}</td>
                                        <td>{commaValue(item.합계)}</td>
                                        <td>{commaValue(item.대한통운지급액)}</td>
                                        <td>{commaValue(item.마진)}</td>
                                        <td>{commaValue(item.기사급여이체)}</td>
                                    </tr>
                                    :item.점포명 === "자양점"?
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{item.운영타입}</td>
                                        <td>{item.점포명}</td>
                                        <td>{item.배송기사명}</td>
                                        <td>{item.배송기사ID}</td>
                                        <td>{item.도급일}</td>
                                        <td>{commaValue(item.총배송처수)}</td>
                                        <td>{commaValue(item.총운행거리)}</td>
                                        <td>{commaValue(item.평균운행거리)}</td>
                                        <td>{commaValue(item.반올림)}</td>
                                        <td>{commaValue(item.건수인센티브)}</td>
                                        <td>{commaValue(item.TR인센티브)}</td>
                                        <td>{commaValue(item.장거리인센티브PPTR)}</td>
                                        <td>{commaValue(item.기본거리인센티브TR)}</td>
                                        <td>{commaValue(item.야간인센티브)}</td>
                                        <td>{commaValue(item.특수권역인센티브)}</td>
                                        <td>{commaValue(item.인센합계)}</td>
                                        <td>{commaValue(item.수기배송)}</td>
                                        <td>{commaValue(item.온도관제미설치)}</td>
                                        <td>{commaValue(item.공제비용)}</td>
                                        <td>{commaValue(item.기본도급료공제대상)}</td>
                                        <td>{commaValue(item.유니폼144)}</td>
                                        <td>{commaValue(item.차량청결383)}</td>
                                        <td>{commaValue(item.기타수당1660)}</td>
                                        <td>{commaValue(item.인센티브2200)}</td>
                                        <td>{commaValue(item.인센티브합)}</td>
                                        <td>{commaValue(item.기본료합계)}</td>
                                        <td>{commaValue(item.합계기본료인센)}</td>
                                        <td>{commaValue(item.백신휴무지원금)}</td>
                                        <td>{commaValue(item.경조하시원금)}</td>
                                        <td>{commaValue(item.기사지급액지입료공제후)}</td>
                                        <td>{commaValue(item.관리비및부가세)}</td>
                                        <td>{commaValue(item.회차및갱신보험)}</td>
                                        <td>{commaValue(item.환경부담금)}</td>
                                        <td>{commaValue(item.협회비)}</td>
                                        <td>{commaValue(item.산재고용보험)}</td>
                                        <td>{commaValue(item.과태료공제)}</td>
                                        <td>{commaValue(item.전월미수및기타비용)}</td>
                                        <td>{commaValue(item.기타분납금)}</td>
                                        <td>{commaValue(item.합계)}</td>
                                        <td>{commaValue(item.대한통운지급액)}</td>
                                        <td>{commaValue(item.마진)}</td>
                                        <td>{commaValue(item.기사급여이체)}</td>
                                    </tr>
                                    :item.점포명 === "하월곡점"?
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{item.운영타입}</td>
                                        <td>{item.점포명}</td>
                                        <td>{item.배송기사명}</td>
                                        <td>{item.배송기사ID}</td>
                                        <td>{item.도급일}</td>
                                        <td>{commaValue(item.총배송처수)}</td>
                                        <td>{commaValue(item.총운행거리)}</td>
                                        <td>{commaValue(item.평균운행거리)}</td>
                                        <td>{commaValue(item.반올림)}</td>
                                        <td>{commaValue(item.건수인센티브)}</td>
                                        <td>{commaValue(item.TR인센티브)}</td>
                                        <td>{commaValue(item.장거리인센티브PPTR)}</td>
                                        <td>{commaValue(item.기본거리인센티브TR)}</td>
                                        <td>{commaValue(item.야간인센티브)}</td>
                                        <td>{commaValue(item.특수권역인센티브)}</td>
                                        <td>{commaValue(item.인센합계)}</td>
                                        <td>{commaValue(item.수기배송)}</td>
                                        <td>{commaValue(item.온도관제미설치)}</td>
                                        <td>{commaValue(item.공제비용)}</td>
                                        <td>{commaValue(item.기본도급료공제대상)}</td>
                                        <td>{commaValue(item.유니폼144)}</td>
                                        <td>{commaValue(item.차량청결383)}</td>
                                        <td>{commaValue(item.기타수당1660)}</td>
                                        <td>{commaValue(item.인센티브2200)}</td>
                                        <td>{commaValue(item.인센티브합)}</td>
                                        <td>{commaValue(item.기본료합계)}</td>
                                        <td>{commaValue(item.합계기본료인센)}</td>
                                        <td>{commaValue(item.백신휴무지원금)}</td>
                                        <td>{commaValue(item.경조하시원금)}</td>
                                        <td>{commaValue(item.기사지급액지입료공제후)}</td>
                                        <td>{commaValue(item.관리비및부가세)}</td>
                                        <td>{commaValue(item.회차및갱신보험)}</td>
                                        <td>{commaValue(item.환경부담금)}</td>
                                        <td>{commaValue(item.협회비)}</td>
                                        <td>{commaValue(item.산재고용보험)}</td>
                                        <td>{commaValue(item.과태료공제)}</td>
                                        <td>{commaValue(item.전월미수및기타비용)}</td>
                                        <td>{commaValue(item.기타분납금)}</td>
                                        <td>{commaValue(item.합계)}</td>
                                        <td>{commaValue(item.대한통운지급액)}</td>
                                        <td>{commaValue(item.마진)}</td>
                                        <td>{commaValue(item.기사급여이체)}</td>
                                    </tr>
                                    :null
                                )
                            }) :null 
                        } 
                        </tbody> 
                    </Table>

                </div>
            </div>
        </>
    )
}

ManagePaySpec.edfaultProps = {
    extension:".xls, .xlsx",
    text : "파일 업로드",
    id: "",
}
export default ManagePaySpec;