import axios from 'axios';
import TokenService from './TokenService';

const API_URL = process.env.REACT_APP_USER_API_URL+"user/";

export default class UserService {

    static async login(userData) {
        const token = TokenService.get();
        if(userData.user_auth !== "A"){
            try {
                let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
                if (isMobile && window.android && typeof window.android.getToken === "function") {
                    let pushToken = await window.android.getToken();
                    if (pushToken) {
                        userData.user_push_token = pushToken;
                        console.log("Push token set:", pushToken);
                    } else {
                        console.warn("Push token is empty.");
                    }
                }
            } catch (error) {
                console.error("Error handling mobile push token:", error);
            }
        }
        let result = await axios.post(API_URL+"signin", userData,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result.data;
    }

    static async logout(){
        const token = TokenService.get();
        
        let result = await axios.post(API_URL+"signout", null,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });

        TokenService.remove();
        return result.data;
    }

    static async signUp(userData) {
        const result = await axios.post(API_URL+"init", userData);
        return result.data;
    }

    static async userList(userData){
        const token = TokenService.get();

        let result = await axios.post(API_URL+"list", userData,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result.data;
    }

    static async userStateMod(userData) {
        const token = TokenService.get();

        const result = await axios.post(API_URL+"stateMod", userData, {
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }

    static async userAuthMod(userData) {
        const token = TokenService.get();

        const result = await axios.post(API_URL+"authMod", userData, {
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }

    static async userDel(userData) {
        const token = TokenService.get();

        const result = await axios.post(API_URL+"del", userData, {
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }

    static async userPwdInit(userData) {
        const token = TokenService.get();

        const result = await axios.post(API_URL+"pwdInit", userData, {
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }
}